import { api, i18n } from "@/config"
import { handleFailedResponse } from "@/helpers/common"
import { showSuccessToast } from "@/helpers/toasts"

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }) => {
    try {
      const { data } = await api.get(baseURI)
      commit("SET_ITEM", data.data)
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  UPDATE_ITEM: async ({ commit }, { formData }) => {
    try {
      const { data } = await api.put(baseURI, formData, { "Content-Type": "multipart/form-data" })
      commit("SET_ITEM", data.data)
      showSuccessToast({ text: i18n.t("company_system.basic_settings.about_updated") })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  }
})
