import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import { extractCommonMutations, buildCommonState } from "../../shared"
import extractActions from "./actions"

const baseURI = "/companies/about"

const store = new StoreItemsModule({ baseURI: baseURI })

store.mergeState(buildCommonState())

store.mergeMutations(extractCommonMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
